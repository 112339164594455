import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import newImage from "../../../assets/new-img.svg";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createWebsiteContent,
  CreateWebsiteContentData,
  CreateWebsiteContentResponse,
  getWebsiteContent,
} from "../../../handlers/contentHandlers";
import { useCookies } from "react-cookie";
import { Oval } from "react-loader-spinner";
import ReactS3Client from "react-aws-s3-typescript";
import { s3Config } from "../../../utils/s3Config";
import { toast } from "react-toastify";
import { validateImage } from "../../../utils/helper";

type TValidateImage = {
  isValid: boolean;
  message: string;
};

const WebsiteContent = () => {
  const queryClient = useQueryClient();

  const [cookies] = useCookies(["sofresh-admin-token"]);
  const [selectedButton, setSelectedButton] = useState("homepage");
  // const [websiteContentsData, setWebsiteContentsData]=useState<any>()

  const [heroSection, setHeroSection] = useState<CreateWebsiteContentData>({
    section: "Hero",
    first_text: "",
    second_text: "",
    image_url: "",
    link: "",
  });

  const [firstSection, setFirstSection] = useState<CreateWebsiteContentData>({
    section: "First section",
    text: "",
    sub_text: "",
    image_url: "",
    link: "",
    action_text: "",
  });

  const [secondSection, setSecondSection] = useState<CreateWebsiteContentData>({
    section: "Second section",
    text: "",
    sub_text: "",
    image_url: "",
  });

  const [uploading, setUploading] = useState({
    uploadHeroImage: false,
    uploadFirstImage: false,
    uploadSecondImage: false,
  });

  const [imageError, setImageError] = useState("");

  function resetUploading() {
    return {
      uploadHeroImage: false,
      uploadFirstImage: false,
      uploadSecondImage: false,
    };
  }

  const handleButtonClick = (button: React.SetStateAction<string>) => {
    setSelectedButton(button);
  };

  const { data: websiteContents, isLoading: isWebsiteContentsLoading } =
    useQuery(["website-contents"], () =>
      getWebsiteContent({
        token: cookies["sofresh-admin-token"],
      })
    );

  const fileHeroInputRef = useRef<HTMLInputElement>(null);
  const fileFirstInputRef = useRef<HTMLInputElement>(null);
  const fileSecondInputRef = useRef<HTMLInputElement>(null);

  // HANDLE INPUT VALUE CHANGE
  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    section: string
  ) => {
    const { name, value } = event.target;

    if (section === "hero") {
      setHeroSection((prevData: CreateWebsiteContentData) => ({
        ...prevData,

        [name]: value,
      }));
    }

    if (section === "first") {
      setFirstSection((prevData: CreateWebsiteContentData) => ({
        ...prevData,

        [name]: value,
      }));
    }

    if (section === "second") {
      setSecondSection((prevData: CreateWebsiteContentData) => ({
        ...prevData,

        [name]: value,
      }));
    }
  };

  // HANDLE IMAGE UPLOAD
  const handleHeroUploadBtnClick = () => {
    if (fileHeroInputRef.current) {
      fileHeroInputRef.current.click();
    }
  };
  const handleFirstUploadBtnClick = () => {
    if (fileFirstInputRef.current) {
      fileFirstInputRef.current.click();
    }
  };
  const handleSecondUploadBtnClick = () => {
    if (fileSecondInputRef.current) {
      fileSecondInputRef.current.click();
    }
  };

  const handleFileUpload = async (file: File) => {
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: "website-contents",
    });
    try {
      const res = await s3.uploadFile(file);
      return res.location;
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const id = e.target.id;
    const file = e.target.files?.[0];
    if (!file) return;
    // setSelectedFile(file);
    setUploading((prevData: any) => ({
      ...prevData,

      [id]: true,
    }));

    if (id === "uploadHeroImage") {
      const validationResult: TValidateImage = await validateImage(file);
      if (!validationResult.isValid) {
        setImageError(validationResult.message);
        setUploading(resetUploading());
        return;
      }
    }

    try {
      setImageError("");
      const imageUrl = await handleFileUpload(file);
      // setImages(String(imageUrl));
      if (id === "uploadHeroImage") {
        setHeroSection((prevData: CreateWebsiteContentData) => ({
          ...prevData,

          image_url: imageUrl as string,
        }));
      }

      if (id === "uploadFirstImage") {
        setFirstSection((prevData: CreateWebsiteContentData) => ({
          ...prevData,

          image_url: imageUrl as string,
        }));
      }

      if (id === "uploadSecondImage") {
        setSecondSection((prevData: CreateWebsiteContentData) => ({
          ...prevData,

          image_url: imageUrl as string,
        }));
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to upload image");
    } finally {
      setUploading(resetUploading());
    }
  };
  // END OF HANDLE IMAGE UPLOAD

  // CREATE BLOG POST FORM SUBMISSION
  const {
    mutate: createWebsiteContentMutation,
    isLoading: isCreateWebsiteContentMutationLoading,
  } = useMutation(createWebsiteContent, {
    onSuccess: async (res: CreateWebsiteContentResponse) => {
      toast.success(res.message);

      await queryClient.invalidateQueries(["website-contents"]);
    },
    onError: (error: unknown) => {
      console.error(error);
      // toast.error(e)
      toast.error("Failed to create website content, please try again!");
    },
  });

  // HANDLE FORM SUBMISSION
  const handleCreateWebsiteContent = async () => {
    try {
      createWebsiteContentMutation({
        token: cookies["sofresh-admin-token"],
        websiteContent: {
          contents: [heroSection, firstSection, secondSection],
        },
      });
    } catch (error) {
      console.error(error);
      toast.error("Failed to create website content, please try again!");
    }
  };

  useEffect(() => {
    if (websiteContents && websiteContents.length > 0) {
      setHeroSection(websiteContents[0].contents[0]);
      setFirstSection(websiteContents[0].contents[1]);
      setSecondSection(websiteContents[0].contents[2]);
    }
  }, [websiteContents]);

  return (
    <main className="content">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex items-center justify-content-between">
              <h1 className="h3">Website Content</h1>
              <button
                type="button"
                onClick={handleCreateWebsiteContent}
                className="btn btn-lg btn-warning"
              >
                {isCreateWebsiteContentMutationLoading ? (
                  <Oval
                    color="white"
                    wrapperClass="mx-4"
                    height={10}
                    width={10}
                  />
                ) : (
                  "Save Changes"
                )}
              </button>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <div className="toggle-bg">
              <div className="d-flex flex-row items-center gap-3">
                <button
                  className={`py-2 px-4 ${
                    selectedButton === "homepage" ? "active" : "inactive"
                  }`}
                  onClick={() => handleButtonClick("homepage")}
                >
                  Homepage
                </button>
                <button
                  className={`py-2 px-4 ${
                    selectedButton === "soFreshStory" ? "active" : "inactive"
                  }`}
                  onClick={() => handleButtonClick("soFreshStory")}
                >
                  The So Fresh Story
                </button>
                <button
                  className={`py-2 px-4 ${
                    selectedButton === "returnPolicy" ? "active" : "inactive"
                  }`}
                  onClick={() => handleButtonClick("returnPolicy")}
                >
                  Return Policy
                </button>
                <button
                  className={`py-2 px-4 ${
                    selectedButton === "privacyPolicy" ? "active" : "inactive"
                  }`}
                  onClick={() => handleButtonClick("privacyPolicy")}
                >
                  Privacy Policy
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5 mx-0">
          <div className="tab-content">
            {selectedButton === "homepage" &&
              (isWebsiteContentsLoading ? (
                <div className="my-5 w-100 d-flex  align-items-center justify-content-center">
                  <Oval height="40" width="40" color="#495057" visible={true} />
                </div>
              ) : (
                <>
                  <div className="mb-5">
                    <h3>Hero Section</h3>
                    <div className="row mt-4">
                      <div className="col-sm-6">
                        <div className="col-sm-6 image-container w-100">
                          <img
                            src={
                              heroSection.image_url
                                ? heroSection.image_url
                                : newImage
                            }
                            alt="hero-img"
                            className="img-fluid w-full"
                          />
                          <div className="centered-button ">
                            {uploading.uploadHeroImage ? (
                              <Oval
                                wrapperClass="ml-2"
                                height={20}
                                width={20}
                              />
                            ) : (
                              <button
                                onClick={handleHeroUploadBtnClick}
                                className="text-white bg-transparent"
                              >
                                Choose New Image
                              </button>
                            )}
                          </div>

                          <input
                            type="file"
                            id="uploadHeroImage"
                            accept="image/*"
                            ref={fileHeroInputRef}
                            style={{ display: "none" }}
                            onChange={handleImageUpload}
                          />
                        </div>
                        {imageError && (
                          <p className="text-danger w-full text-left">
                            {imageError}
                          </p>
                        )}
                      </div>

                      <div className="col-sm-6 mb-3">
                        <h4>Hero Text</h4>
                        <div className="mt-3">
                          <h6>First text</h6>
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            name="first_text"
                            onChange={(e) => handleChange(e, "hero")}
                            value={heroSection.first_text ?? heroSection.text}
                            placeholder="More Than Food. It's A Lifestyle"
                          />

                          <h6 className="mt-3">Second text</h6>
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            name="second_text"
                            onChange={(e) => handleChange(e, "hero")}
                            value={heroSection.second_text}
                            placeholder="More Than Food. It's A Lifestyle"
                          />

                          {/* <div className="mb-3 mt-3">
                            <h4>Link</h4>
                            <div className="mt-3">
                              <input
                                className="form-control form-control-lg"
                                type="text"
                                name="link"
                                onChange={(e) => handleChange(e, "hero")}
                                value={firstSection.link}
                                placeholder="Please provide action button link"
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-5">
                    <h3>First Section</h3>
                    <div className="row mt-4">
                      <div className="col-sm-6 image-container">
                        <img
                          src={
                            firstSection.image_url
                              ? firstSection.image_url
                              : newImage
                          }
                          alt="hero-img"
                          className="img-fluid w-full"
                        />
                        <div className="centered-button">
                          {uploading.uploadFirstImage ? (
                            <Oval wrapperClass="ml-2" height={20} width={20} />
                          ) : (
                            <button
                              onClick={handleFirstUploadBtnClick}
                              className="text-white"
                            >
                              Choose New Image
                            </button>
                          )}
                        </div>

                        <input
                          type="file"
                          id="uploadFirstImage"
                          accept="image/*"
                          ref={fileFirstInputRef}
                          style={{ display: "none" }}
                          onChange={handleImageUpload}
                        />
                      </div>

                      <div className="col-sm-6">
                        <div className="mb-3">
                          <h4>Main Text</h4>
                          <div className="mt-3">
                            <input
                              className="form-control form-control-lg"
                              type="text"
                              name="text"
                              onChange={(e) => handleChange(e, "first")}
                              value={firstSection.text}
                              placeholder="More Than Food. It's A Lifestyle"
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <h4>Sub Text</h4>
                          <div className="mt-3">
                            <textarea
                              className="form-control form-control-lg"
                              rows={3}
                              name="sub_text"
                              onChange={(e) => handleChange(e, "first")}
                              value={firstSection.sub_text}
                              placeholder="Lorem Ipsum Lorem Ipsum Lorem"
                            />
                          </div>
                        </div>

                        <div className="mb-3">
                          <h4>Action Text</h4>
                          <div className="mt-3">
                            <textarea
                              className="form-control form-control-lg"
                              rows={2}
                              name="action_text"
                              onChange={(e) => handleChange(e, "first")}
                              value={firstSection.action_text}
                              placeholder="Lorem Ipsum Lorem Ipsum Lorem"
                            />
                          </div>
                        </div>

                        <div className="mb-3">
                          <h4>Link</h4>
                          <div className="mt-3">
                            <input
                              className="form-control form-control-lg"
                              type="text"
                              name="link"
                              onChange={(e) => handleChange(e, "first")}
                              value={firstSection.link}
                              placeholder="Please provide action button link"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-5">
                    <h3>Second Section</h3>
                    <div className="row mt-4">
                      <div className="col-sm-6 image-container">
                        <img
                          src={
                            secondSection.image_url
                              ? secondSection.image_url
                              : newImage
                          }
                          alt="second-img"
                          className="img-fluid w-full"
                        />
                        <div className="centered-button">
                          {uploading.uploadSecondImage ? (
                            <Oval wrapperClass="ml-2" height={20} width={20} />
                          ) : (
                            <button
                              onClick={handleSecondUploadBtnClick}
                              className="text-white"
                            >
                              Choose New Image
                            </button>
                          )}
                        </div>

                        <input
                          type="file"
                          id="uploadSecondImage"
                          accept="image/*"
                          ref={fileSecondInputRef}
                          style={{ display: "none" }}
                          onChange={handleImageUpload}
                        />
                      </div>

                      <div className="col-sm-6">
                        <div className="mb-3">
                          <h4>Main Text</h4>
                          <div className="mt-3">
                            <input
                              className="form-control form-control-lg"
                              type="text"
                              name="text"
                              onChange={(e) => handleChange(e, "second")}
                              value={secondSection.text}
                              placeholder="More Than Food. It's A Lifestyle"
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <h4>Sub Text</h4>
                          <div className="mt-3">
                            <textarea
                              className="form-control form-control-lg"
                              rows={3}
                              name="sub_text"
                              onChange={(e) => handleChange(e, "second")}
                              value={secondSection.sub_text}
                              placeholder="Lorem Ipsum Lorem Ipsum Lorem"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            {selectedButton === "soFreshStory" && (
              <p>Content for The So Fresh Story</p>
            )}
            {selectedButton === "returnPolicy" && (
              <p>Content for Return Policy</p>
            )}
            {selectedButton === "privacyPolicy" && (
              <p>Content for Privacy Policy</p>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default WebsiteContent;
