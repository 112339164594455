import { AxiosError, AxiosResponse } from "axios";
import { axiosClient } from "../utils/axiosClient";

/*=============================================
=              BLOGS HANDLERS TYPES           =
=============================================*/

export type CreateWebsiteContentData = {
  section: string;
  first_text?: string;
  second_text?: string;
  text?: string;
  sub_text?: string;
  image_url: string;
  link?: string;
  action_text?: string;
};

export type CreateWebsiteContentProps = {
  token: string;
  websiteContent: { contents: Array<CreateWebsiteContentData> };
};

export type CreateWebsiteContentResponse = {
  status: boolean;
  message: string;
};

type GetContentOptions = {
  token: string;
};

export type GetWebsiteContentsData = {
  id: number;
  contents: CreateWebsiteContentData[];
};

export type GetWebsiteContentsResponse = GetWebsiteContentsData[];
/*=============================================
=              WEBSITE CONTENT HANDLERS              =
=============================================*/
export const createWebsiteContent = async ({
  token,
  websiteContent,
}: CreateWebsiteContentProps): Promise<CreateWebsiteContentResponse> => {
  try {
    const { data }: AxiosResponse<CreateWebsiteContentResponse> =
      await axiosClient.post(`admin/website-contents`, websiteContent, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
    console.log(data);
    return data;
  } catch (error: unknown) {
    console.error(error);
    throw new Error("An error occurred. Please try again.");
  }
};

export const getWebsiteContent = async ({
  token,
}: GetContentOptions): Promise<GetWebsiteContentsResponse> => {
  try {
    const response: AxiosResponse<GetWebsiteContentsResponse> =
      await axiosClient.get(`admin/website-contents`, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      });
    console.log(response);

    return response.data;
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    console.error(axiosError);
    throw new Error("An error occurred. Please try again.");
  }
};
